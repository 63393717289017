.home {
    background-image: url('../assets/images/home_background.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #333;
    position: relative;
}

.home::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: inherit;
    background-size: inherit;
    background-position: inherit;
    background-repeat: inherit;
    filter: blur(2px) brightness(0.7) contrast(0.9) saturate(0.8);
    z-index: 1;
}

.home h1, .home p, .home button {
    position: relative;
    z-index: 2;
}

.home h1 {
    font-size: clamp(2rem, 5vw, 3rem); /* 根据视口宽度调整字体大小 */
    margin-bottom: 20px;
    color: #fff;
    width: 80%; /* 固定宽度 */
    text-align: center;
    line-height: 1.2;
    word-wrap: break-word; /* 自动换行 */
}

.home p {
    font-size: clamp(1rem, 3vw, 1.5rem); /* 根据视口宽度调整字体大小 */
    margin-bottom: 30px;
    color: #fff;
    width: 80%; /* 固定宽度 */
    text-align: center;
    line-height: 1.5;
    word-wrap: break-word; /* 自动换行 */
}

.home button {
    background-color: #2b7a0b;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    z-index: 2;
}

.home button:hover {
    background-color: #1e5809;
}
