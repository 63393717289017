.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #f9f9f9; /* 浅灰色背景 */
    color: #333; /* 深灰色文字 */
    border-bottom: 2px solid #eaeaea; /* 辅助色底部边框 */
  }
  
  .logo a {
    color: #2b7a0b; /* 绿色突出色 */
    text-decoration: none;
    font-size: 24px;
    font-weight: bold;
  }
  
  .nav-links a {
    margin: 0 15px;
    color: #333; /* 深灰色文字 */
    text-decoration: none;
    transition: color 0.3s ease; /* 平滑过渡 */
  }
  
  .nav-links a:hover {
    color: #2b7a0b; /* 悬停时变为绿色 */
    text-decoration: underline;
  }
  
  .language-switcher button {
    background: none;
    border: 2px solid #2b7a0b; /* 绿色边框 */
    color: #2b7a0b; /* 绿色文字 */
    cursor: pointer;
    margin-left: 10px;
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .language-switcher button:hover {
    background-color: #2b7a0b; /* 悬停时绿色背景 */
    color: white; /* 悬停时白色文字 */
  }
  