.footer {
    padding: 20px;
    background-color: #f9f9f9; /* 浅灰色背景 */
    color: #333; /* 深灰色文字 */
    text-align: center;
    border-top: 2px solid #eaeaea; /* 辅助色顶部边框 */
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer-nav a {
    margin: 5px 15px; /* 增加间距使布局更均匀 */
    color: #2b7a0b; /* 绿色文字 */
    text-decoration: none;
    transition: color 0.3s ease; /* 平滑过渡 */
  }
  
  .footer-nav a:hover {
    color: #1e5809; /* 悬停时深绿色 */
    text-decoration: underline;
  }
  
  .footer p {
    margin-bottom: 10px; /* 与导航链接保持适当间距 */
  }
  