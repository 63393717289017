.use-cases-page {
    display: flex;
    min-height: 100vh;
  }
  
  .case-list {
    width: 25%;
    background-color: #f7f7f7;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0,0,0,0.1);
  }
  
  .case-list ul {
    list-style-type: none;
    padding: 0;
  }
  
  .case-list li {
    cursor: pointer;
    padding: 10px 0;
    color: #2b7a0b;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
  }
  
  .case-list li:hover {
    text-decoration: underline;
  }
  
  .case-details {
    width: 75%;
    padding: 40px;
    background-color: #fff;
  }
  
  .case-section {
    margin-bottom: 50px;
  }
  
  .case-section h2 {
    font-size: 2rem;
    color: #2b7a0b;
    margin-bottom: 20px;
  }
  
  .case-section p {
    font-size: 1.2rem;
    color: #333;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .case-section img {
    max-width: 80%; /* Scale the image to a maximum of 80% of the container width */
    height: auto; /* Maintain aspect ratio */
    display: block;
    margin: 0 auto; /* Center the image horizontally */
}
