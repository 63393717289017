.about-us {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin: auto;
    padding: 40px;
    color: #333; /* Text color */
}

.team-section {
    display: flex;
    justify-content: space-around;
    margin-bottom: 40px;
    text-align: center; /* Center text for founder and co-founder */
}

.founder-section, .cofounder-section {
    flex: 1;
}

.team-photo {
    width: 150px;
    height: auto; /* Maintain aspect ratio */
    border-radius: 50%;
    margin-bottom: 10px;
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.2); /* Add shadow for better photo focus */
}

.left-column {
    flex: 1;
    padding-right: 20px;
    border-right: 1px solid #ccc; /* Vertical divider */
    margin-bottom: 20px;
}

.right-column {
    flex: 2;
    padding-left: 20px;
    text-align: left; /* Align text on the right column to the left */
}

.section-title {
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: #2b7a0b; /* Green theme highlight */
}

.mission, .vision-section, .contact-section {
    margin-bottom: 40px;
}

h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

p {
    font-size: 1.2rem;
    line-height: 1.6;
}

.contact-info p {
    margin: 5px 0;
}
