.product-page {
    display: flex;
    min-height: 100vh;
}

.product-list {
    width: 20%;
    background-color: #f4f4f4;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0,0,0,0.1);
}

.product-list ul {
    list-style: none;
    padding: 0;
}

.product-list li {
    margin: 10px 0;
    cursor: pointer;
    color: #2b7a0b;
}

.product-list li:hover {
    text-decoration: underline;
}

.product-details {
    width: 60%;
    padding: 20px;
    overflow-y: auto;
}

.product-section {
    margin-bottom: 50px;
}

.product-section img {
    max-width: 80%; /* Scale the image to a maximum of 80% of the container width */
    height: auto; /* Maintain aspect ratio */
    display: block;
    margin: 0 auto; /* Center the image horizontally */
}

.product-section h2 {
    margin-top: 0;
}

.product-section p {
    line-height: 1.6;
    color: #333;
    text-align: left;
}
